import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { APIData } from 'common-utils/dist/models/api';

import { ApiEndpointsService } from './api-endpoints.service';
import { CookieService } from 'ngx-shared-services';
import { EventNode } from '../models/event-node';
import { Registration } from '../models/registration';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  private registration = new BehaviorSubject<Registration>(null);
  registration$ = this.registration.asObservable();


  constructor(
    private http: HttpClient,
    private apiService: ApiEndpointsService,
    private cookieService: CookieService,
  ) {}

  updateRegistration(node: EventNode): Observable<Registration> {
    const { emsUrl } = this.apiService;
    const session = this.cookieService.getCookie('enoc_session');
    return this.http.get<APIData<Registration>>(`${emsUrl}/v1/registration/${node.registration_id}`, { withCredentials:true }).pipe(
      map(reg => {
        const data = {id: reg['id'], display_label: reg['display_label'], curtailment_plan: reg['energy_reduction_plan']}
        this.registration.next(data);
        return data
      }),
      catchError(() => { // This should stay here until there is a home for ERPs in FastDR
        console.error(`Unable to retrieve registration for event_node_id=${node.id}`);
        return of({} as Registration);
      }),
    );
  }

  fetchRegistration(node: EventNode): Observable<Registration> {
    const { emsUrl } = this.apiService;
    const session = this.cookieService.getCookie('enoc_session');
    return this.http.get<APIData<Registration>>(`${emsUrl}/v1/registration/${node.registration_id}`, { withCredentials:true }).pipe(
      map(reg => {
        const data = {id: reg['id'], display_label: reg['display_label'], curtailment_plan: reg['energy_reduction_plan']}
        return data
      }),
      catchError(() => { // This should stay here until there is a home for ERPs in FastDR
        console.error(`Unable to retrieve registration for event_node_id=${node.id}`);
        return of({} as Registration);
      }),
    );
  }

  getRegistration(): Observable<Registration> {
    return this.registration$;
  }
}
