import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { PositionObject, TooltipPositionerPointObject } from 'highcharts';
import More from 'highcharts/highcharts-more';
import * as moment from 'moment';
import { CookieService } from 'ngx-shared-services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RegistrationType } from '../../../models/event-node';
import { DispatchExpandService } from '../../../services/dispatch-expand.service';
import { NativeService } from '../../../services/native.service';
import { DispatchGraphViewModel } from './graph-interval.viewModel';
import { DateTime } from 'luxon'

More(Highcharts);
@Component({
  selector: 'app-graph-interval-data',
  templateUrl: './graph-interval-data.component.html',
  styleUrls: ['./graph-interval-data.component.scss'],
})
export class GraphIntervalDataComponent implements OnInit, OnDestroy, OnChanges {

  chart: Highcharts.Chart;
  options: Highcharts.Options;
  @Input() viewModel: DispatchGraphViewModel;

  readonly isMobile: boolean;
  private _reflow: number;
  private _destroy$ = new Subject<void>();
  private shouldRefresh: boolean;
  locale;

  constructor(
    private translateService: TranslateService,
    private dispatchExpandService: DispatchExpandService,
    private nativeService: NativeService,
    private cookieSerice: CookieService
  ) {
    this.shouldRefresh = true;
    this.isMobile = this.nativeService.isMobileDevice();

    const { animating$ } = this.dispatchExpandService;
    animating$.pipe(
      takeUntil(this._destroy$),
    ).subscribe((animating) => {
      if (animating) {
        this._reflow = window.setInterval(() => this.chart.reflow(), 1);
      } else {
        clearInterval(this._reflow);
      }
    });
  }

  private mobileChartPad(): void {
    // pad 40px to move data off the y-axis labels
    const chartExtraMargin = 40;
    Highcharts.wrap((Highcharts as any).Axis.prototype, 'setAxisSize', function() {
      this.proceed.call(this);
      if (this.isXAxis) {
        this.left = chartExtraMargin;

        this.len = Math.max(this.horiz ? this.width : this.height, 0);
        this.pos = this.horiz ? this.left : this.top;
      }
    });
  }

  ngOnInit(): void {
    if (this.isMobile) {
      this.mobileChartPad();
    }
    this.locale = this.cookieSerice.getI18NLocale();
    Highcharts.wrap((Highcharts as any).seriesTypes.arearange.prototype, 'translate',function() {

      this.proceed.apply(this, Array.prototype.slice.call(arguments, 1));
      let i = 0;
      this.points.forEach((point) => {
        if (point.high !== null || point.low !== null) {
          if(point.low == null) {
            point.isNull = false;
            point.plotY = point.plotLow = this.yAxis.len;
            point.plotHigh = this.yAxis.translate(
              point.high,
              0,
              1,
              0,
              1,
            );

            if (0 && this.points[i + 1]) {
              point.plotX = this.points[i + 1].plotX;
            }
          }
          if(point.high === null) {
            point.isNull = false;
            point.plotHigh = 0;
            point.plotY = point.plotLow = this.yAxis.translate(
              point.low,
              0,
              1,
              0,
              1,
            );
            if (0 && this.points[i + 1]) {
              point.plotX = this.points[i + 1].plotX;
            }
          }
        }
        i += 1;
      });
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {currentValue, firstChange, previousValue} = changes.viewModel
    if(!this.viewModel) return;
    let chartWidth:any = 200 ;
    const x = moment(this.viewModel.dataRange[0]);
    const y = moment(this.viewModel.dataRange[1]);
    const units = moment.duration(y.diff(x)).as('minutes');
    const controller = this;

    // Calculate post ramping time
    let postRampingPeriod = 1200000; // default
    const dispatchConf = this.viewModel.product.dispatch_conf
    if (dispatchConf.post_bonus_minutes > 0 || dispatchConf.post_ramping_period > 0) {
      const post_bonus_ms = dispatchConf.post_bonus_minutes * 60 * 1000;
      postRampingPeriod = post_bonus_ms > dispatchConf.post_ramping_period
                            ? post_bonus_ms
                            : dispatchConf.post_ramping_period;
    }

    if(this.viewModel.frequency === 60000 && units > 59) {
      chartWidth = 582 + 87 * (units/5);
    }
    if(this.viewModel.frequency === 300000 && units > 295) {
      chartWidth = 582 + 18 * (units/5);
    }
    this.shouldRefresh = currentValue.eventNode !== previousValue?.eventNode
    if(this.shouldRefresh || firstChange) {
      this.options = {
        title : { text : '' },
  
        chart: {
          scrollablePlotArea: {
            minWidth: chartWidth,
          },
          style: {
            fontFamily: 'Roobert'},
  
          },
        series: [
          { // bogus series to get the label into the legend
            type: 'area',
            name: 'Baseline Adjustment',
            color: '#D4F87F',
            showInLegend: this.viewModel.show_baseline_adjustment,
          },
          { // bogus series to get the label into the legend
            type: 'area',
            name: this.translateService.instant('INTERVAL_GRAPH.LEGEND_BONUS_MINS'),
            color: '#BFDBFB',
            showInLegend: this.viewModel.show_bonus_minutes,
          },
          {
            data: this.viewModel.target,
            type: this.viewModel.showTargetRange ? 'arearange' : 'area',
            name: this.translateService.instant('INTERVAL_GRAPH.TOOLTIP_TARGET'),
            color: '#59bc5f',
            fillOpacity: .25,
            lineWidth: 1,
            threshold: this.viewModel.showGenLoad ? Infinity : -Infinity,
            marker: {
              enabled: false,
              symbol: 'circle',
            },
          },
          {
            data: this.viewModel.demand,
            name: this.translateService.instant('INTERVAL_GRAPH.TOOLTIP_DEMAND'),
            type: 'spline',
            color: '#461E7D',
            lineWidth: 2,
            visible: this.viewModel.showDemand,
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  fillColor: 'white',
                  lineColor: '#461E7D',
                },
              },
            },
            showInLegend: this.viewModel.showDemand,
          },
          {
            data: this.viewModel.performance,
            name: this.translateService.instant('INTERVAL_GRAPH.TOOLTIP_PERF'),
            type: 'spline',
            color: '#461E7D',
            lineWidth: 2,
            visible: this.viewModel.showGenLoad,
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  fillColor: 'white',
                  lineColor: '#461E7D',
                },
              },
            },
            showInLegend: this.viewModel.showGenLoad,
          },
          {
            data: this.viewModel.baseline,
            name: this.translateService.instant('INTERVAL_GRAPH.TOOLTIP_BASELINE'),
            type: 'spline',
            color: '#717171',
            lineWidth: 2,
            zIndex: -1,
            marker: { enabled: false },
            states: { hover: { enabled: false } },
            visible: this.viewModel.showBaseline,
            showInLegend: this.viewModel.showBaseline,
          },
        ],
        plotOptions: {
          series: {
            animation: this.shouldRefresh, softThreshold: false ,
            turboThreshold: 100000
          }
          },
        tooltip: {
          outside: false,
          shared: true,
          useHTML: true,
          borderWidth: 1,
          borderColor: '#EEEEEE',
          borderRadius: 2,
          backgroundColor: 'white',
          positioner: ((labelWidth: number, labelHeight: number, point: TooltipPositionerPointObject): PositionObject => {
            if (this.isMobile) {
              return {x: point.plotX + 40, y: point.plotY};
            }
            return {x: point.plotX, y: point.plotY};
          }),
          formatter: (() => {
            const instance = this;
            const performanceLabel = this.translateService.instant('INTERVAL_GRAPH.TOOLTIP_PERF');
            return function() {
              // @ts-ignore
              const expectedCapacity: number = controller.viewModel.eventNode.expected_capacity.value;
              const regType: RegistrationType = controller.viewModel.eventNode.registration_type as RegistrationType;
              const timeValue: string = (instance.options.xAxis as Highcharts.AxisOptions).labels!.formatter!.call({ value: this.x });
              const baselinePoint = this.points.find((point) => point.point['field'] === 'baseline');
              const meteredPoint = this.points.find((point) => point.point['field'] === 'metered');
              const targetPoint = this.points.find((point) => point.point['field'] === 'target');
              const performancePoint = this.points.find((point) => point.point['field'] === 'performance');
              let performanceVal, performancePerCent;
  
              const seriesLabelMap = {
                spline: '&mdash;',
                arearange: '&#9679;',
                area: '&#9679;',
              };
  
              function getPointValue(inst, point, y): string {
                if (instance.viewModel.showTargetRange && point && point.series && point.series.name === inst.translateService.instant('INTERVAL_GRAPH.TOOLTIP_TARGET') && point.high !== null) {
                  return point.low.toFixed(2) + ' - ' + point.high.toFixed(2);
                }
                return Math.round(y).toString();
              }
  
              function getTargetValue(point): string {
                if (instance.viewModel.showTargetRange) {
                  return point.point.low.toFixed(2) + ' - ' + point.point.high.toFixed(2);
                }
                return Math.round(point.y).toString();
              }

              function shouldShowShortfall(meteredPoint, targetPoint): boolean {
                return controller.viewModel.registrationType === RegistrationType.LOAD_DROP_TO
                  && !!meteredPoint && !!targetPoint && (meteredPoint.y > targetPoint.y);
              }
  
              let rows = '';
              if(controller.viewModel.showGenLoad) {
                // GENERATOR, STORAGE
                if(!!performancePoint && !!performancePoint.point['metered']) {
                  rows += '<tr><td></td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_GEN_LOAD')  + '</td><td>' + Math.round(performancePoint.point.y).toString() + ' kW</td></tr>'
                }
                if(!!performancePoint) {
                  performanceVal = performancePoint.y;
                  performancePerCent = expectedCapacity > 0 ? performanceVal / expectedCapacity * 100 : 0;
                  rows += '<tr><td style="color:#461E7D">&mdash;</td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_PERF')  + '</td><td>' + Math.round(performanceVal).toString() + ' kW (' + Math.round(performancePerCent) +'%)</td></tr>'
                }
                if(!!targetPoint) {
                  rows += '<tr><td style="color:#59bc5f">&#9679</td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_TARGET')  + '</td><td>' + getTargetValue(targetPoint) + ' kW</td></tr>'
                }
              } else {
                // LOAD, DEMAND_ON
                if(!!baselinePoint) {
                  rows += '<tr><td style="color:#717171">&mdash;</td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_BASELINE')  + '</td><td>' + Math.round(baselinePoint.y).toString() + ' kW</td></tr>'
                }
                if(!!meteredPoint) {
                  rows += '<tr><td style="color:#461E7D">&mdash;</td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_DEMAND')  + '</td><td>' + Math.round(meteredPoint.y).toString() + ' kW</td></tr>'
                }
                if(!!targetPoint) {
                  rows += '<tr><td style="color:#59bc5f">&#9679</td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_TARGET')  + '</td><td>' + getTargetValue(targetPoint) + ' kW</td></tr>'
                }
                if (shouldShowShortfall(meteredPoint, targetPoint)) {
                  const shortfall = Math.round(meteredPoint.y - targetPoint.y);
                  rows += '<tr><td></td><td>Shortfall</td><td>' + shortfall + ' kW</td></tr>'
                }
                if(baselinePoint &&  meteredPoint && targetPoint) {
                  performanceVal = baselinePoint.y - meteredPoint.y;
                  performancePerCent = expectedCapacity === 0 ? 0 : Math.round(performanceVal / expectedCapacity * 100);
                  rows += '<tr><td></td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_PERF')  + '</td><td>' + Math.round(performanceVal).toString() + ' kW (' + Math.round(performancePerCent).toString() +'%)</td></tr>'
                }
              }
  
              return '<span>' + timeValue  + '</span><table>' + rows + '</table>'
            };
          })(),
        },
        legend: {reversed: true},
        yAxis: {
          title: {text: this.isMobile ? '' : this.viewModel.showGenLoad ? this.translateService.instant('INTERVAL_GRAPH.LBL_Y_AXIS_GEN_LOAD') : this.translateService.instant('INTERVAL_GRAPH.LBL_Y_AXIS')},
          // min: 0,
          labels: (this.isMobile) ? ({align: 'left', x: 15, y: -3 }) : ({}),
          startOnTick: false
        },
        xAxis: {
          type: 'datetime',
          max: this.viewModel.to + postRampingPeriod,
          minTickInterval: 60000 * 5,
          labels: {
            formatter: (() => {
              const instance = this;
              return function() {
  
                const { tzShort, timezone } = instance.viewModel;
                //let tzShort =  DateTime.fromMillis(this.value).setZone(`${timezone}`).offsetNameShort;
                const time = DateTime.fromMillis(this.value).setZone(`${timezone}`).toFormat(instance.viewModel.product?.reporting_interval_ms < 300000 ? 'HH:mm:ss' : 'HH:mm:ss');
                return `${time} ${tzShort}`;
              };
            })(),
          },
          tickWidth: 0,
          crosshair: { width: 2 },
          plotBands: [
            {
              color: '#F1F1F2',
              from: 0,
              to: this.viewModel.from,
            }, {
              color: '#F1F1F2',
              from: this.viewModel.to,
              to: Infinity,
            },
            {
              color: '#BFDBFB',
              from: this.viewModel.bonusMinutesFrom,
              to: this.viewModel.bonusMinutesTo,
            }, 
            this.viewModel.show_baseline_adjustment ? {
              color: '#D4F87F',
              from: this.viewModel.baselineAdjFrom,
              to: this.viewModel.baselineAdjTo,
            } : {},
          ],
        },
        time: {
          timezoneOffset: this.viewModel.tzOffset,
        },
        credits: { enabled: false },
      }
    } else {
      this.chart.update({
        title : { text : '' },
  
        chart: {
          scrollablePlotArea: {
            minWidth: chartWidth,
          },
          style: {
            fontFamily: 'Roobert'},
  
          },
        series: [
          { // bogus series to get the label into the legend
            type: 'area',
            name: 'Baseline Adjustment',
            color: '#D4F87F',
            showInLegend: this.viewModel.show_baseline_adjustment,
          },
          { // bogus series to get the label into the legend
            type: 'area',
            name: this.translateService.instant('INTERVAL_GRAPH.LEGEND_BONUS_MINS'),
            color: '#BFDBFB',
            showInLegend: this.viewModel.show_bonus_minutes,
          },
          {
            data: this.viewModel.target,
            type: this.viewModel.showTargetRange ? 'arearange' : 'area',
            name: this.translateService.instant('INTERVAL_GRAPH.TOOLTIP_TARGET'),
            color: '#59bc5f',
            fillOpacity: .25,
            lineWidth: 1,
            threshold: this.viewModel.showGenLoad ? Infinity : -Infinity,
            marker: {
              enabled: false,
              symbol: 'circle',
            },
          },
          {
            data: this.viewModel.demand,
            name: this.translateService.instant('INTERVAL_GRAPH.TOOLTIP_DEMAND'),
            type: 'spline',
            color: '#461E7D',
            lineWidth: 2,
            visible: this.viewModel.showDemand,
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  fillColor: 'white',
                  lineColor: '#461E7D',
                },
              },
            },
            showInLegend: this.viewModel.showDemand,
          },
          {
            data: this.viewModel.performance,
            name: this.translateService.instant('INTERVAL_GRAPH.TOOLTIP_PERF'),
            type: 'spline',
            color: '#461E7D',
            lineWidth: 2,
            visible: this.viewModel.showGenLoad,
            marker: {
              enabled: false,
              symbol: 'circle',
              radius: 2,
              states: {
                hover: {
                  fillColor: 'white',
                  lineColor: '#461E7D',
                },
              },
            },
            showInLegend: this.viewModel.showGenLoad,
          },
          {
            data: this.viewModel.baseline,
            name: this.translateService.instant('INTERVAL_GRAPH.TOOLTIP_BASELINE'),
            type: 'spline',
            color: '#717171',
            lineWidth: 2,
            zIndex: -1,
            marker: { enabled: false },
            states: { hover: { enabled: false } },
            visible: this.viewModel.showBaseline,
            showInLegend: this.viewModel.showBaseline,
          },
        ],
        plotOptions: {
          series: {
            animation: this.shouldRefresh, softThreshold: false ,
            turboThreshold: 100000
          }
          },
        tooltip: {
          outside: false,
          shared: true,
          useHTML: true,
          borderWidth: 1,
          borderColor: '#EEEEEE',
          borderRadius: 2,
          backgroundColor: 'white',
          positioner: ((labelWidth: number, labelHeight: number, point: TooltipPositionerPointObject): PositionObject => {
            if (this.isMobile) {
              return {x: point.plotX + 40, y: point.plotY};
            }
            return {x: point.plotX, y: point.plotY};
          }),
          formatter: (() => {
            const instance = this;
            const performanceLabel = this.translateService.instant('INTERVAL_GRAPH.TOOLTIP_PERF');
            return function() {
              // @ts-ignore
              const expectedCapacity: number = controller.viewModel.eventNode.expected_capacity.value;
              const regType: RegistrationType = controller.viewModel.eventNode.registration_type as RegistrationType;
              const timeValue: string = (instance.options.xAxis as Highcharts.AxisOptions).labels!.formatter!.call({ value: this.x });
              const baselinePoint = this.points.find((point) => point.point['field'] === 'baseline');
              const meteredPoint = this.points.find((point) => point.point['field'] === 'metered');
              const targetPoint = this.points.find((point) => point.point['field'] === 'target');
              const performancePoint = this.points.find((point) => point.point['field'] === 'performance');
              let performanceVal, performancePerCent;
  
              const seriesLabelMap = {
                spline: '&mdash;',
                arearange: '&#9679;',
                area: '&#9679;',
              };
  
              function getPointValue(inst, point, y): string {
                if (instance.viewModel.showTargetRange && point && point.series && point.series.name === inst.translateService.instant('INTERVAL_GRAPH.TOOLTIP_TARGET') && point.high !== null) {
                  return point.low.toFixed(2) + ' - ' + point.high.toFixed(2);
                }
                return Math.round(y).toString();
              }
  
              function getTargetValue(point): string {
                if (instance.viewModel.showTargetRange) {
                  return point.point.low.toFixed(2) + ' - ' + point.point.high.toFixed(2);
                }
                return Math.round(point.y).toString();
              }

              function shouldShowShortfall(meteredPoint, targetPoint): boolean {
                return controller.viewModel.registrationType === RegistrationType.LOAD_DROP_TO
                  && !!meteredPoint && !!targetPoint && (meteredPoint.y > targetPoint.y);
              }
  
              let rows = '';
              if(controller.viewModel.showGenLoad) {
                // GENERATOR, STORAGE
                if(!!performancePoint && !!performancePoint.point['metered']) {
                  rows += '<tr><td></td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_GEN_LOAD')  + '</td><td>' + Math.round(performancePoint.point.y).toString() + ' kW</td></tr>'
                }
                if(!!performancePoint) {
                  performanceVal = performancePoint.y;
                  performancePerCent = expectedCapacity > 0 ? performanceVal / expectedCapacity * 100 : 0;
                  rows += '<tr><td style="color:#461E7D">&mdash;</td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_PERF')  + '</td><td>' + Math.round(performanceVal).toString() + ' kW (' + Math.round(performancePerCent) +'%)</td></tr>'
                }
                if(!!targetPoint) {
                  rows += '<tr><td style="color:#59bc5f">&#9679</td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_TARGET')  + '</td><td>' + getTargetValue(targetPoint) + ' kW</td></tr>'
                }
              } else {
                // LOAD, DEMAND_ON, DROP_TO
                if(!!baselinePoint) {
                  rows += '<tr><td style="color:#717171">&mdash;</td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_BASELINE')  + '</td><td>' + Math.round(baselinePoint.y).toString() + ' kW</td></tr>'
                }
                if(!!meteredPoint) {
                  rows += '<tr><td style="color:#461E7D">&mdash;</td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_DEMAND')  + '</td><td>' + Math.round(meteredPoint.y).toString() + ' kW</td></tr>'
                }
                if(!!targetPoint) {
                  rows += '<tr><td style="color:#59bc5f">&#9679</td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_TARGET')  + '</td><td>' + getTargetValue(targetPoint) + ' kW</td></tr>'
                }
                if (shouldShowShortfall(meteredPoint, targetPoint)) {
                  const shortfall = Math.round(meteredPoint.y - targetPoint.y);
                  rows += '<tr><td></td><td>Shortfall</td><td>' + shortfall + ' kW</td></tr>'
                }
                if(baselinePoint &&  meteredPoint && targetPoint) {
                  performanceVal = baselinePoint.y - meteredPoint.y;
                  performancePerCent = expectedCapacity === 0 ? 0 : Math.round(performanceVal / expectedCapacity * 100);
                  rows += '<tr><td></td><td>' + controller.translateService.instant('INTERVAL_GRAPH.TOOLTIP_PERF')  + '</td><td>' + Math.round(performanceVal).toString() + ' kW (' + Math.round(performancePerCent).toString() +'%)</td></tr>'
                }
              }
  
              return '<span>' + timeValue  + '</span><table>' + rows + '</table>'
            };
          })(),
        },
        legend: {reversed: true},
        yAxis: {
          title: {text: this.isMobile ? '' : this.viewModel.showGenLoad ? this.translateService.instant('INTERVAL_GRAPH.LBL_Y_AXIS_GEN_LOAD') : this.translateService.instant('INTERVAL_GRAPH.LBL_Y_AXIS')},
          // min: 0,
          labels: (this.isMobile) ? ({align: 'left', x: 15, y: -3 }) : ({}),
          startOnTick: false
        },
        xAxis: {
          type: 'datetime',
          max: this.viewModel.to + postRampingPeriod,
          minTickInterval: 60000 * 5,
          labels: {
            formatter: (() => {
              const instance = this;
              return function() {
  
                const { tzShort, timezone } = instance.viewModel;
                //let tzShort =  DateTime.fromMillis(this.value).setZone(`${timezone}`).offsetNameShort;
                const time = DateTime.fromMillis(this.value).setZone(`${timezone}`).toFormat(instance.viewModel.product?.reporting_interval_ms < 300000 ? 'HH:mm:ss' : 'HH:mm:ss');
                return `${time} ${tzShort}`;
              };
            })(),
          },
          tickWidth: 0,
          crosshair: { width: 2 },
          plotBands: [
            {
              color: '#F1F1F2',
              from: 0,
              to: this.viewModel.from,
            }, {
              color: '#F1F1F2',
              from: this.viewModel.to,
              to: Infinity,
            },
            {
              color: '#BFDBFB',
              from: this.viewModel.bonusMinutesFrom,
              to: this.viewModel.bonusMinutesTo,
            }, 
            this.viewModel.show_baseline_adjustment ? {
              color: '#D4F87F',
              from: this.viewModel.baselineAdjFrom,
              to: this.viewModel.baselineAdjTo,
            } : {},
          ],
        },
        time: {
          timezoneOffset: this.viewModel.tzOffset,
        },
        credits: { enabled: false },
      }, true)
    }
  }

  saveInstance(chartInstance: Highcharts.Chart): void {
    this.chart = chartInstance;
  }
}
