<div>
  <div *ngIf="!isMobile">
    <div class="row metadata" *ngIf="(eventNode$ | async) as eventNode">
      <div class="col left">
        <span class="display-label">{{ eventNode.model.display_label }}</span>
        <span *ngIf="eventNode.site_to_registration_map && eventNode.site_to_registration_map.length > 1">{{ registration?.display_label }}</span>
      </div>
      <div class="col right">
        <span>{{ eventNode.event.program.name }}</span>
        <span>{{ eventNode.model.event_node_start_dttm_utc | eventWindow : eventNode.model.event_node_end_dttm_utc : eventNode.event.product?.timezone : eventNode.program_time_zone_abbr :'absolute' }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="isMobile">
    <div class="row metadata" *ngIf="(eventNode$ | async) as eventNode">
      <div class="col left">
        <span class="display-label">{{ eventNode.model.display_label }}</span>
        <span>{{ eventNode.event.program.name }}</span>
        <span>{{ eventNode.model.event_node_start_dttm_utc | eventWindow: eventNode.model.event_node_end_dttm_utc : eventNode.event.product?.timezone : eventNode.program_time_zone_abbr: 'absolute' }}</span>
      </div>
    </div>
  </div>
</div>
