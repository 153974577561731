import { Component, Input } from '@angular/core';
import { Registration } from 'src/app/models/registration';

@Component({
  selector: 'app-reduction-plan',
  templateUrl: './reduction-plan.component.html',
  styleUrls: ['./reduction-plan.component.scss'],
})
export class ReductionPlanComponent {

  @Input() registration: Registration;

}
