import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, filter } from 'rxjs/operators';
import { EventNode } from '../../../models/event-node';
import { DispatchSelectService } from '../../../services/dispatch-select.service';
import {NativeService} from '../../../services/native.service';
import { Registration } from 'src/app/models/registration';

@Component({
  selector: 'app-dashboard-metadata',
  templateUrl: './dashboard-metadata.component.html',
  styleUrls: ['./dashboard-metadata.component.scss'],
})
export class DashboardMetadataComponent {

  @Input() registration: Registration;
  readonly eventNode$: Observable<EventNode>;
  readonly isMobile: boolean;
  constructor(
    private dispatchSelectService: DispatchSelectService,
    private nativeService: NativeService,
  ) {
    const { selected$ } = this.dispatchSelectService;
    this.eventNode$ = selected$.pipe(
      filter<EventNode>((node) => !!node),
      shareReplay(1),
    );
    this.isMobile = this.nativeService.isMobileDevice();
  }
}
