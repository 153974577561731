import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIData } from 'common-utils/dist/models/api';
import { ApiTime } from 'common-utils/dist/models/time';
import { isNull } from 'common-utils/dist/typescript-utils';
import * as _ from 'lodash';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data.min';
import { IMqttServiceOptions, MqttConnectionState, MqttService } from 'ngx-mqtt';
import { CookieService } from 'ngx-shared-services';
import { MessageService } from 'primeng/api';
import { forkJoin, from, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, mergeMap, retry, tap } from 'rxjs/operators';
import { Connection, ConnectionRenew } from '../models/connection';
import { Event, EventAPI, KeyPerformanceMetric, SourceSystem } from '../models/event';
import { EventNode, EventNodeAPI, RegistrationType } from '../models/event-node';
import { EventNodeIot } from '../models/iot';
import { Product, ProductAPI } from '../models/product';
import {
  parseId,
  parseLabel,
  parseNullable,
  parsePerformanceStatus,
  parseProgressStatus,
  parseStatusPercent,
  parseUomValue,
} from '../models/shared';
import { normalise, orNaN, orString, tryParse } from '../models/shared/utils';
import { ApiEndpointsService } from './api-endpoints.service';
import { DispatchSelectService } from './dispatch-select.service';
import { flattenDisplayLabel } from './format.service';
import { UserService } from './user.service';
import { filteredProductIds } from 'src/environments/variables';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class EventsApiService {
  events: Event[];
  eventsAPI: EventAPI[];
  events$ = new Subject<Event[]>();
  eventNode$ = new ReplaySubject<EventNode>();
  eventNodeUpdate$ = new ReplaySubject<EventNode>();
  eventUpdate$ = new ReplaySubject<Event>();
  productsMap = {};
  capturedNodes = {};
  baseUrl;
  emsUrl;
  params;
  headers;
  firstPass = true;

  constructor(private http: HttpClient, private mqttService: MqttService, private cookieService: CookieService, private apiService: ApiEndpointsService, private userService: UserService, private dispatchSelectService: DispatchSelectService, private toaster: MessageService) {
    this.baseUrl  = this.apiService.baseUrl;
    this.emsUrl = this.apiService.emsUrl;
    const session = this.cookieService.getCookie('enoc_session');
    this.headers = new HttpHeaders().append('enoc_session', session);
    this.params = new HttpParams().append('hierarchy', 'false');
    moment.tz.add("Coordinated Universal Time|EST EDT EWT EPT|50 40 40 40|01010101010101010101010101010101010101010101010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261t0 1nX0 11B0 1nX0 11B0 1qL0 1a10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 RB0 8x40 iv0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|21e6");

  }

  refreshEvents(){
    this.events$.next(this.events);
  }
  getEvents() {
    const controller = this;
    let updatedEvent;
    //this.http.get<APIData<EventAPI[]>>(`${this.baseUrl}/events`, { params: this.params, headers: this.headers }).pipe(

    this.http.get<APIData<EventAPI[]>>(`${this.emsUrl}/v1/eps_events`, { params: {hierarchy: false}, withCredentials: true }).pipe(
      retry(2),
    ).subscribe(
      (resp) => {
        if(!resp.data || !resp.data.length){
          this.events$.next([]);
        }
        const newEvents = _.differenceBy(resp.data, this.eventsAPI, 'event_id');
        if(newEvents.length) {
          this.eventsAPI = _.filter(resp.data, (o)=>{
            if (filteredProductIds[environment.environment].includes(o.product_id)) {
              return false
            }
            return o.event_action_type !== 'STANDBY'
          });
          if(this.eventsAPI.length){
            this.getProducts();
          } else {
            this.events$.next([]);
          }

        } else {
          resp.data.forEach((e)=>{
            updatedEvent = controller.parseEvent(e);
            controller.eventUpdate$.next(updatedEvent)
          })
        }
        setTimeout(() => {this.getEvents();}, 30000);
      },
      (err) => {
        this.toaster.add({key: 'connection-error', severity: 'error', sticky: false});
        console.log(err);
      },
    );
  }

  getEventNodes(event: Event) {
    if(!event) return from(new Promise<void>((resolve) => resolve())) ;
    const controller = this;
    return this.http.get<APIData<EventNodeAPI[]>>(`${this.emsUrl}/v1/event/${event.id}/flat_node_list`, { params: {}, withCredentials:true}).pipe(
      map(({ data }) => {
        const siteRegistrationMap = new Map<string, Set<string>>();
    
        data.forEach(item => {
          if (!siteRegistrationMap.has(item.site_id)) {
            siteRegistrationMap.set(item.site_id, new Set());
          }
          siteRegistrationMap.get(item.site_id)!.add(item.registration_id);
        });
    
        return data.map(item => ({
          ...item,
          site_to_registration_map: Array.from(siteRegistrationMap.get(item.site_id) ?? [])
        }));
      }),
      map((raw) => raw.map((api) => this.parseEventNode(api as EventNodeAPI, event))),
      tap((nodes) => {
        event.event_nodes = nodes;
        controller.capturedNodes[event.id] = nodes;
        controller.subscribeToTopic(_.map(event.event_nodes, 'id'), 'EVENT_NODE', (msg: EventNodeIot) => {
          let node = _.find(this.capturedNodes[event.id], ['id', msg.event_node_id]);
          let i = this.capturedNodes[event.id].indexOf(node)
          node = this.updateNode(node, msg);
          this.capturedNodes[event.id][i] = node;
          controller.eventNodeUpdate$.next(node)
        });
      }),
      catchError((err)=> {
        this.toaster.add({key: 'no-nodes-error', severity: 'error', sticky: false});
        console.log(err);
        return from(new Promise<void>((resolve) => resolve())) ;
      }),
    );
  }

  getProducts() {
    const controller = this;
    // get unique product ids
    const uPIds:any[] = _.map(_.uniqBy(this.eventsAPI, 'product_id'), 'product_id');
    const uniqueProdIds: Observable<any[]> = of((_.map(_.uniqBy(this.eventsAPI, 'product_id'), 'product_id')));
    uniqueProdIds.pipe(
      mergeMap((ids) => {
        if(!ids.length) {
          return of<any[]>([]);
        }
        return forkJoin(
          ids.map((id) => {
              if(id) {
                if(this.productsMap[id]) {
                  return from(new Promise((resolve) => resolve(this.productsMap[id]))) ;
                } else {
                  return this.http.get<APIData<ProductAPI>>(`${this.emsUrl}/v1/product/${id}`, {withCredentials: true}).pipe(
                    map((resp) => {
                      this.productsMap[id] = resp.data;
                      return resp;
                    }),
                    catchError((err)=> {
                      console.log(err);
                      return from(new Promise<void>((resolve) => resolve())) ;
                    }),
                  );
                }

              } else {
                return from(new Promise((resolve) => resolve(null))) ;
              }

            },
          ));
      }),
    ).subscribe(
      (resp) => {
        const arr = [];
        controller.eventsAPI.forEach((api) => {
          arr.push(this.parseEvent(api));
        });
        // @ts-ignore
        if(controller.firstPass) {
          controller.firstPass = false;
          const ev0 = arr.find((e) => e.progress_status === 'DURING') || arr[0];
          this.getEventNodes(ev0).subscribe(
            () => {
              controller.events = arr;
              this.events$.next(this.events);
              this.dispatchSelectService.select(ev0.event_nodes[0]);
              //controller.eventNodeUpdate$.next(ev0.event_nodes[0]);
            }
        );
        } else {
          controller.events = arr;
          //this.events$.next(this.events);
        }
      },
    );
  }

  subscribeToTopic(ids, resource, parseFunction) {
    const controller = this;

    controller.http.post<APIData<Connection>>(`${controller.emsUrl}/v1/connection`, {ids, resource},{withCredentials: true }).pipe(
      map(({ data }) => data),
    ).subscribe(
      (response) => {
        const connection = response;

        const match = /(.*?):\/\/(.*?)(\/.*)/.exec(connection.endpoint_url);
        if (!match) return;
        const [, protocol, hostname, path] = match;

        if (controller.mqttService.state.value === MqttConnectionState.CLOSED) {
          controller.mqttService.connect({protocol: (protocol as IMqttServiceOptions['protocol']), hostname, path, port: 443});
        }

        const obs = controller.mqttService.observe(connection.topic);
        const sub = obs.pipe().subscribe(
          (resp) => {
            const msg = JSON.parse(new TextDecoder('utf-8').decode(resp.payload));
            parseFunction(msg);
          },
          (error) => {
            console.log(`Error receiving changes for ${resource} :  ${JSON.stringify(error)}`);
          });

        // Renew after 90% of the expire time is up
        setTimeout(() => {
          controller.renewSubscription(connection.client_id);
        }, connection.expires_seconds * .5 * 1000);

      }, (error) => {
        console.log(`Error subscribing to changes for ${resource}`);
      });
  }

  renewSubscription(client_id:string ) {
    const controller = this;
    this.http.get<APIData<ConnectionRenew>>(`${this.emsUrl}/connection/renew/${client_id}`, {withCredentials: true}).pipe(
    ).subscribe(
      (response) => {
        const connection = response.data;
        // Call itself again when 90% of the previous time is up to renew
        setTimeout(() => {
          controller.renewSubscription(client_id);
        }, connection.expires_seconds * .5 * 1000);
      },
      (error) => {
        console.log('Error renewing subscription for client ID ' + client_id);
      });
  }

  getProgressStatus(eventApi): string {
    const startTime = eventApi.event_node_statistics.start_dttm_utc;
    const endTime = eventApi.event_node_statistics.end_dttm_utc;
    if (moment().utc().isBefore(moment(startTime))) {
      return 'BEFORE';
    } else if (!endTime || moment().utc().isBefore(moment(endTime))) {
      return 'DURING';
    } else {
      return 'AFTER';
    }
  }

  getOverallPerformance(api: EventAPI) {
    let perfValue;
    const perfMetric = api.product ? api.product.key_performance_metric :  KeyPerformanceMetric.AVERAGE;
    switch (perfMetric) {
      case KeyPerformanceMetric.AVERAGE:
        perfValue = api.average_performance_value;
        break;
      case  KeyPerformanceMetric.MAXIMUM:
        perfValue = api.non_coincident_maximum_performance_value;
        break;
      case KeyPerformanceMetric.MINIMUM:
        perfValue = api.non_coincident_minimum_performance_value;
        break;
    }
    return perfValue;
  }

  getPerformancePercentage(eventAPI: EventAPI) {
    const expectedCapacity = eventAPI.sum_expected_capacity_value;
    const performanceVal = this.getOverallPerformance(eventAPI);
    const performancePerCent = expectedCapacity === 0 ? 0 : performanceVal / expectedCapacity;
    return performancePerCent;
  }

  parseEvent(api: EventAPI) {
    const locale = this.userService.user ? this.userService.user.default_locale : 'en_US';
    const eventPerformance: number | null = !isNull(api.event_performance_percent) ? api.event_performance_percent / 100 : null; // dividing by 100 here so the percent label is formatted correctly
    const product: ProductAPI = this.productsMap[api.product_id];
    const tzToUse = product ? product.timezone : api.full_time_zone;
    api.event_progress_status = this.getProgressStatus(api);
    const getStartTime = ()=>{
      if(api.event_start_dttm_locale && !tzToUse) {
        return api.event_start_dttm_locale;
      } else {
        return moment.tz(api.event_node_statistics.start_dttm_utc, tzToUse).format(ApiTime.momentFormat)
      }
    }
    const getEndTime = ()=>{
      if(api.event_end_dttm_locale && !tzToUse) {
        return api.event_end_dttm_locale;
      } else {
        return moment.tz(api.event_node_statistics.end_dttm_utc, tzToUse).format(ApiTime.momentFormat)
      }
    }

    if(!api.event_node_statistics.end_dttm_utc && product) {
      api.event_node_statistics.end_dttm_utc = moment(api.event_start_dttm_utc).add(product.max_event_duration, 'milliseconds').toISOString();
    }
    const event: Event = {
      id: api.event_id,
      model: {
        ...api,
      },
      program: {
        id: api.program_id,
        name: parseLabel(api.program_name, locale),
        short: !!(api.program_name_short) ? parseLabel(api.program_name_short, locale) : null,
      },
      program_time_zone_abbr: api.program_time_zone_abbr,
      progress_status: parseNullable(api.event_progress_status, parseProgressStatus),
      performance: (api.event_progress_status === 'AFTER') ? {
        value: this.getOverallPerformance(api),
        uom: api.average_performance_uom,
        status: parseNullable(api.average_performance_status, parsePerformanceStatus),
        percentage: this.getPerformancePercentage(api),
      } : {
        ...parseUomValue(api, 'last_current_performance'),
        status: parseNullable(api.last_current_performance_status, parsePerformanceStatus),
        percentage: orNaN(eventPerformance),
      },
      sum_expected_capacity: {uom: api.sum_expected_capacity_uom || 'kW', value: api.sum_expected_capacity_value ? Math.round(api.sum_expected_capacity_value) : 0},
      event_nodes: this.capturedNodes[api.event_id] ? this.capturedNodes[api.event_id] : [],
      event_start: tryParse<ApiTime>(() => new ApiTime(orString( getStartTime()))),
      event_end: tryParse<ApiTime>(() => new ApiTime(orString(getEndTime()))),
      product,
      source_system_type: api.source_system_type,
      notification_time_utc: api.notification_time_utc,
      len_total_event_nodes: api.event_node_statistics.total_event_nodes,
      len_active_event_nodes: api.event_node_statistics.active_event_nodes,
      len_pending_event_nodes: api.event_node_statistics.pending_event_nodes,
      len_opted_out_event_nodes: api.event_node_statistics.opted_out_event_nodes,
      site_display_label: flattenDisplayLabel(locale, api.event_node_statistics.site_display_label),
      event_start_dttm_utc: api.event_start_dttm_utc,
      event_end_dttm_utc: api.event_end_dttm_utc,
      full_time_zone: api.full_time_zone,
      non_coincident_maximum_performance_value: api.non_coincident_maximum_performance_value,
      non_coincident_minimum_performance_value: api.non_coincident_minimum_performance_value,

    };
    if (!event.program_time_zone_abbr && event.product && event.event_start) {
      event.program_time_zone_abbr = moment.tz(event.event_start.time, event.product.timezone).zoneAbbr();
    }

    return event;
  }

  getOverallNodePerformance(api: EventNodeAPI, product: ProductAPI) {
    let perfValue;
    const productUOM = product.prez_conf.prefered_prez_demand_uom ?? api.last_current_performance_uom;
    if (api.registration_type === RegistrationType.LOAD_DROP_TO) {
      const performanceValueKW = Math.round(Math.abs(api.last_current_performance_value))
      perfValue = (productUOM.toLocaleLowerCase() === "kw" ? performanceValueKW : (performanceValueKW/1000)).toLocaleString();
      return perfValue;
    }
    const perfMetric = product ? product.key_performance_metric :  KeyPerformanceMetric.AVERAGE;
    switch (perfMetric) {
      case KeyPerformanceMetric.AVERAGE:
        perfValue = api.average_performance_value;
        break;
      case  KeyPerformanceMetric.MAXIMUM:
        perfValue = api.maximum_performance_value;
        break;
      case KeyPerformanceMetric.MINIMUM:
        perfValue = api.minimum_performance_value;
        break;
    }
    return perfValue;
  }

  getNodePerformanceUOM(api: EventNodeAPI, product: ProductAPI) {
    const productUOM = product.prez_conf.prefered_prez_demand_uom ?? api.last_current_performance_uom;
    const perfUOM = api.average_performance_uom
    if (api.registration_type === RegistrationType.LOAD_DROP_TO) {
      return productUOM;
    }

    return perfUOM;
  }

  getNodeCapacity(api: EventNodeAPI, product: ProductAPI) {
    const capacity = {
      value: api.expected_capacity_value,
      uom: api.expected_capacity_uom
    }
    if (api.registration_type === RegistrationType.LOAD_DROP_TO) {
      const productUOM = product.prez_conf.prefered_prez_demand_uom ?? api.last_current_performance_uom;
      const capacityValue = (productUOM.toLocaleLowerCase() === "kw" ? capacity.value : (capacity.value/1000))
      return {
        value: capacityValue,
        uom: productUOM
      };
    }

    return capacity;
  }

  parseEventNode(api: EventNodeAPI, event: Event) {
    const locale = this.userService.user ? this.userService.user.default_locale : 'en_US';
    const tzOffset = event.event_start.tzOffset.numberOffset;
    let node: EventNode = {
      event,
      model: {
        ...api,
        display_label: parseLabel(api.site_display_label, locale),
      },
      ...parseId(api, 'event_node'),
      site: {...parseId(api, 'site'), display_label: parseLabel(api.site_display_label, locale)},
      flags: (() => {
        const {is_fsl_indicator} = api;
        return {is_fsl_indicator};
      })(),
      program_time_zone_abbr: event.program_time_zone_abbr,
      last_current_performance_percentage: api.last_current_performance_percentage,
      last_current_target_value: api.last_current_target_value,
      fsl: parseUomValue(api, 'firm_service_level'),
      expected_capacity: this.getNodeCapacity(api, event.product),
      last_current_performance_dttm: tryParse<ApiTime>(() => new ApiTime(orString(moment(api.last_current_performance_dttm_utc).utcOffset(tzOffset).format(ApiTime.momentFormat)))),
      last_current_metered: parseUomValue(api, 'last_current_metered'),
      performance: normalise<EventNode['performance']>('percentage', (d) => d / 100, (event.progress_status === 'AFTER') ? {
        value: this.getOverallNodePerformance(api, event.product),
        uom: this.getNodePerformanceUOM(api, event.product),
        status: parseNullable(api.average_performance_status ?? api.last_current_performance_status, parsePerformanceStatus),
        percentage: api.average_performance_percentage,
      } : {
        ...parseUomValue(api, 'last_current_performance'),
        ...parseStatusPercent(api, 'last_current_performance'),
      }),
      event_node_start: api.event_node_start_dttm_utc ? tryParse<ApiTime>(() => new ApiTime(orString( moment(api.event_node_start_dttm_utc).utcOffset(tzOffset).format(ApiTime.momentFormat) ))) : event.event_start,
      event_node_end: api.event_node_end_dttm_utc ? tryParse<ApiTime>(() => new ApiTime(orString( moment(api.event_node_end_dttm_utc).utcOffset(tzOffset).format(ApiTime.momentFormat) ))) : event.event_end,
      initial_notif_time: api.initial_notif_time,
      registration_type: api.registration_type || RegistrationType.LOAD,
      estimate_performance_ind: true,
      pre_event_buffer: event.source_system_type === SourceSystem.CLASSIC_DR ? 7200000 : 1200000,
      post_event_buffer: event.source_system_type === SourceSystem.CLASSIC_DR ? 7200000 : 1200000,
      adjustment_window_start: api.adjustment_window_start,
      adjustment_window_end: api.adjustment_window_end,
      registration_id: api.registration_id,
      site_to_registration_map: api.site_to_registration_map
    };

    if(event.source_system_type !== SourceSystem.CLASSIC_DR) {
      // From here, we have to adjust the pre_event_buffer o account for the addition of ramping period or bonus minutes.
      // see if there's a difference between the actual event start time and the start time we're displaying in the graph.
      // use event.event_start as comparator as it contains the actual event start time, where event.chart_start_time may have been modified
      // @ts-ignore
      const startTimesDiff = moment.duration(moment(node.event_node_start.fullDate).diff(moment(event.event_start.fullDate))).as('milliseconds');


      if(event.product != null) {
        let bonus_minutes = event.product.bonus_minutes || 0;
        let ramp_period = event.product.dispatch_conf?.ramping_period || 0;
        node.pre_event_buffer = Math.max(node.pre_event_buffer, (startTimesDiff + bonus_minutes * 60000), (startTimesDiff + ramp_period));
      }
    }

    return node;
  }

  updateNode(node: EventNode, update: EventNodeIot): EventNode {
    const updatedModel = {
      ...node.model,
      average_performance_value:                    update.average_performance_value,
      average_performance_uom:                      update.average_performance_uom,
      average_performance_percentage:               update.average_performance_percentage,
      average_performance_status:                   update.average_performance_status,
      expected_capacity_value:                      update.expected_capacity_value,
      expected_capacity_uom:                        update.expected_capacity_uom,
      last_current_metered_value:                   update.last_current_metered_value,
      last_current_metered_uom:                     update.last_current_metered_uom,
      last_current_performance_value:               update.last_current_performance_value,
      last_current_performance_uom:                 update.last_current_performance_uom,
      last_current_performance_percentage:          update.last_current_performance_percentage,
      last_current_performance_status:              update.last_current_performance_status,
      last_current_performance_dttm_utc:            update.last_current_performance_dttm_utc,
      last_current_performance_dttm_program_locale: update.last_current_performance_dttm_program_locale,
      source_system_type:                           update.source_system_type,
    };
    return this.parseEventNode(updatedModel, node.event);
  }

}
