import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { EventsApiService } from '../../services/events-api.service';
import { EventListService } from '../../services/event-list.service';
import { NativeService } from '../../services/native.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { DispatchExpandService } from '../../services/dispatch-expand.service';
import * as _ from 'lodash'
import { Event } from '../../models/event';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { MixPanelService } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
  animations: [
    trigger('panelToggle', [
      transition(':enter', [
        style({ 'margin-left': -320 }),
        animate('200ms 0ms ease-in', style({ 'margin-left': 0 })),
      ]),
      transition(':leave', [
        animate('200ms ease-out', style({ 'margin-left': -320 })),
      ]),
    ]),
  ],
})
export class EventListComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  events;
  beforeEvents = [];
  duringEvents = [];
  afterEvents = [];
  currentSort = 'byPerformanceOrProgramName';
  readonly isMobile: boolean;
  selectedOption;

  readonly sortOptions = [
    { display_label: 'DISPATCH-LIST.SORT-BY.PERFORMANCE', key: 'byPerformanceOrProgramName' },
    { display_label: 'DISPATCH-LIST.SORT-BY.REDUCTION',   key: 'byCapacityOrProgramName' },
    { display_label: 'DISPATCH-LIST.SORT-BY.START',       key: 'byEventStart' },
    { display_label: 'DISPATCH-LIST.SORT-BY.END',         key: 'byEventEnd' },
  ];

  constructor(
    private eventService: EventsApiService,
    private dispatchExpandService: DispatchExpandService,
    private mixpanelService: MixPanelService,
    private nativeService: NativeService, private translateService: TranslateService) {
    this.isMobile = this.nativeService.isMobileDevice();
    const { events$ } = this.eventService;
    events$.pipe().subscribe(
      (resp) => {
        this.events = resp;
        this.organizeEvents();
      }
    )
  }

  ngOnInit() {
    this.selectedOption = 'byPerformanceOrProgramName';
  }

  organizeEvents() {
    //apply current sort
    switch (this.currentSort) {
      case 'byPerformanceOrProgramName':
        this.events = _.sortBy(this.events, ['performance.percentage', 'program.name']);
        break;
      case 'byCapacityOrProgramName':
        this.events = _.sortBy(this.events, ['sum_expected_capacity.value', 'program.name']);
        break;
      case 'byEventStart':
        this.events = _.sortBy(this.events, ['event_start.time']);
        break;
      case 'byEventEnd':
        this.events = _.sortBy(this.events, ['event_end.time']);
        break;
    }

    this.beforeEvents = _.filter(this.events, ['progress_status','BEFORE']);
    this.duringEvents = _.filter(this.events, ['progress_status','DURING']);
    this.afterEvents = _.filter(this.events, ['progress_status','AFTER']);
  }

  onChangeSort(val) {
    this.currentSort = val;
    this.organizeEvents();
    this.mixpanelService.changeSort(val);
    console.log(val);
  }

  close(): void {
    this.dispatchExpandService.toggle(false);
  }

  @HostListener('@panelToggle.start') onAnimationStart(): void {
    this.dispatchExpandService.animating$.next(true);
  }
  @HostListener('@panelToggle.done') onAnimationDone(): void {
    this.dispatchExpandService.animating$.next(false);
  }
}
