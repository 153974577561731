<div class="event-node row" [ngClass]="highlightClass$ | async" (click)="onClick()" (onHover)="onHover($event)">
  <div class="column performance">
    <div class="performance-indicator" [ngClass]="indicatorClass"></div>
    <span>{{ perfValue }}</span>
  </div>
  <div class="column location" (mouseenter)="onMouseEnter($event)" (mouseleave)="onMouseLeave()">
    <span>
      {{ location }}
    </span>
    <app-tooltip [isVisible]="showTooltip" [ngStyle]="tooltipStyles">
      <div style="padding: 10px;">
        <ul>
          <li><strong>Site: </strong>{{ location }}</li>
          <li *ngIf="eventNode.site_to_registration_map?.length > 1"><strong>Site Details:</strong> {{ registration?.display_label }}</li>
          <li><strong>Reduction: </strong>{{ reduction }}</li>
        </ul>
      </div>
    </app-tooltip>
  </div>
  <div class="column reduction">
    <span>{{ reduction }}</span>
  </div>
</div>
