import { Component, Input, OnInit } from '@angular/core';
import { EventsApiService } from '../../../../../services/events-api.service';
import { EventNode } from '../../../../../models/event-node';
import { DispatchSelectService } from '../../../../../services/dispatch-select.service';
import { NativeService } from '../../../../../services/native.service';
import { DispatchExpandService } from '../../../../../services/dispatch-expand.service';
import { TranslateService } from '@ngx-translate/core';
import { PercentPipe } from '@angular/common';
import { orString } from '../../../../../models/shared/utils';
import { PerformanceClassPipe } from '../../../../../pipes/performance-class.pipe';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { HoverEvent } from 'ngx-ux-components';
import { MixPanelService } from 'src/app/services/mixpanel.service';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration.service';


@Component({
  selector: 'app-nodes-list-item',
  templateUrl: './nodes-list-item.component.html',
  styleUrls: ['./nodes-list-item.component.scss']
})
export class NodesListItemComponent implements OnInit {


  @Input() eventNode: EventNode;
  registration: Registration;
  performance: {
    indicatorClass: string;
    displayLabel: string;
  };
  indicatorClass;
  perfValue;
  reduction;
  location;
  isMobile: boolean;
  selected: boolean;
  highlightClass: string;
  highlightClass$: Observable<string>;
  private _hovered$ = new Subject<boolean>();
  showTooltip = false;
  tooltipStyles: any = {};

  constructor(private eventsService: EventsApiService,
              private dispatchSelectService: DispatchSelectService,
              private nativeService: NativeService,
              private dispatchExpandService: DispatchExpandService,
              private translate: TranslateService,
              private percentPipe: PercentPipe,
              private performanceClassPipe: PerformanceClassPipe,
              private mixpanelService: MixPanelService,
              private registrationService: RegistrationService
            ) {

    this.isMobile = this.nativeService.isMobileDevice();
    const selected$ = this.dispatchSelectService.selected$.pipe(
      map((selected) => selected === this.eventNode),
      startWith(false),
    );
    const hovered$ = this._hovered$.pipe(
      startWith(false),
    );
    const highlighted$ = combineLatest(hovered$, selected$).pipe(
      map(([hovered, selected]) => hovered || selected),
    );
    this.highlightClass$ = highlighted$.pipe(
      map((highlighted) => (highlighted) ? `${this.performance.indicatorClass}-light` : ''),
      shareReplay(1),
    );
    const { eventNodeUpdate$ } = this.eventsService;
    eventNodeUpdate$.pipe().subscribe(
      (resp) => {
        if(this.eventNode && this.eventNode.id == (resp as EventNode).id) {
          this.format(resp);
        }
      }
    )

  }

  ngOnInit() {
    this.performance = {
      indicatorClass: this.performanceClassPipe.transform(this.eventNode),
      displayLabel: orString(this.percentPipe.transform(this.eventNode.performance.percentage, '0.0-0'), '?'),
    };
    this.format(this.eventNode);

    const {eventNode$} = this.eventsService;
    eventNode$.pipe().subscribe(
      (node: EventNode) => {
        if(node.id === this.eventNode.id) {
          setTimeout(()=>this.format(this.eventNode))
        }
      }
    )

    if(this.eventNode.site_to_registration_map?.length > 1) {
      this.registrationService.fetchRegistration(this.eventNode).subscribe(
        (data) => {
          this.registration = data
        }
      );
    }
  }

  format(node: EventNode) {
    if(node) {
      this.location = node.site.display_label;
      this.reduction = node.expected_capacity ? this.translate.instant('UOMVALUE', node.expected_capacity) : '?';
      this.perfValue = orString(this.percentPipe.transform(node.performance.percentage, '0.0-0'), '?');
      this.indicatorClass = this.performanceClassPipe.transform(node);
    }
  }

  onClick() {
    this.dispatchSelectService.select(this.eventNode);
    this.mixpanelService.selectSite(this.eventNode.site.display_label);
    if (this.isMobile) {
      this.dispatchExpandService.toggle();
    }
  }

  onHover({ hovering }: HoverEvent): void {
    this._hovered$.next(hovering);
  }

  onMouseEnter(event: MouseEvent) {
    this.showTooltip = true;
    const target = event.target as HTMLElement;
    const rect = target.getBoundingClientRect();
    this.tooltipStyles = {
      top: `${rect.top + window.scrollY + target.offsetHeight - 20}px`,
      left: `${rect.right + window.scrollX}px`,
      position: 'absolute'
    };
  }

  onMouseLeave() {
    this.showTooltip = false;
  }
}
