import { PercentPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChartModule } from 'angular2-highcharts';
import { HighchartsStatic } from 'angular2-highcharts/dist/HighchartsService';
import * as highcharts from 'highcharts';
import { GlobalFooterModule } from 'ngx-global-contact-footer';
import { GlobalNavModule } from 'ngx-global-nav';
import { MqttModule, MqttService } from 'ngx-mqtt';
import { AnalyticsService, NOC_ANALYTICS } from 'ngx-shared-services';
import { NgxUxComponentsModule } from 'ngx-ux-components';
import { analytics } from 'noc-analytics';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/app-header/app-header.component';
import { ContactDetailsComponent } from './components/dashboard/contact-details/contact-details.component';
import { DashboardMetadataComponent } from './components/dashboard/dashboard-metadata/dashboard-metadata.component';
import { DashboardSummaryComponent } from './components/dashboard/dashboard-summary/dashboard-summary.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { GraphIntervalDataComponent } from './components/dashboard/graph-interval-data/graph-interval-data.component';
import { ReductionPlanComponent } from './components/dashboard/reduction-plan/reduction-plan.component';
import { DispatchListToggleComponent } from './components/dispatch-list-toggle/dispatch-list-toggle.component';
import { ToastMessagesComponent } from './components/toast-messages/toast-messages.component';
import { EventTimerPipe } from './pipes/event-timer.pipe';
import { EventWindowPipe } from './pipes/event-window.pipe';
import { PerformanceClassPipe } from './pipes/performance-class.pipe';
import { PerformanceShowPipe } from './pipes/performance-show.pipe';
import { NativeService } from './services/native.service';
import { MatSelectModule} from '@angular/material/select';
import { UserService } from './services/user.service';
import { NodesListComponent } from './components/event-list/event-card/nodes-list/nodes-list.component';
import { NodesListItemComponent } from './components/event-list/event-card/nodes-list/nodes-list-item/nodes-list-item.component';
import { EventCardComponent } from './components/event-list/event-card/event-card.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { AppRoutingModule } from './app-routing.module';
import { DispatchScheduleViewComponent } from './components/dispatch-schedule-view/dispatch-schedule-view.component';
import { DispatchPerformanceViewComponent } from './components/dispatch-performance-view/dispatch-performance-view.component';
import { SharedModule } from './shared/shared.module';

import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { TableModule, Table, TableService } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { InputTextModule } from 'primeng/inputtext';
import { BlockableUI, FilterMetadata, FilterService, OverlayService, PrimeNGConfig, PrimeTemplate, SelectItem, SortMeta } from 'primeng/api'
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {PaginatorModule} from 'primeng/paginator';
import {TooltipModule} from 'primeng/tooltip';
import { ScheduleComponent } from './components/dispatch-schedule-view/schedule/schedule.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

export function windowFactory(): any {
  return window;
}

declare var require: any;
export function highchartsFactory() {
  return require('highcharts');
}

@NgModule({
    imports: [
        BrowserModule,
        ButtonModule,
        ChartModule.forRoot(require('highcharts')),
        MqttModule.forRoot({connectOnCreate: false}),
        GlobalNavModule.forRoot({namespace: 'ngx-global-nav.'}),
        GlobalFooterModule.forRoot({namespace: 'ngx-global-contact-footer.'}),
        InputTextModule,
        MatSelectModule,
        NgxUxComponentsModule.forRoot({namespace: 'ngx-ux-components.'}),
        PanelModule,
        TableModule,
        ToastModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AppRoutingModule,
        RippleModule,
        MultiSelectModule,
        DropdownModule,
        PaginatorModule,
        TooltipModule,
        ScrollPanelModule,
        SharedModule
    ],
  declarations: [
    AppComponent,
    HeaderComponent,
    DispatchListToggleComponent,
    DashboardComponent,
    PerformanceClassPipe,
    PerformanceShowPipe,
    EventWindowPipe,
    EventTimerPipe,
    GraphIntervalDataComponent,
    DashboardMetadataComponent,
    DashboardSummaryComponent,
    ReductionPlanComponent,
    ContactDetailsComponent,
    ToastMessagesComponent,
    NodesListComponent,
    NodesListItemComponent,
    EventCardComponent,
    EventListComponent,
    DispatchScheduleViewComponent,
    DispatchPerformanceViewComponent,
    ScheduleComponent,
  ],
  providers: [
    AnalyticsService,
    { provide: NOC_ANALYTICS, useValue: analytics },
    { provide: HighchartsStatic, useValue: highchartsFactory },
    PerformanceClassPipe,
    PerformanceShowPipe,
    EventTimerPipe,
    EventWindowPipe,
    FilterService,
    PercentPipe,
    MessageService,
    MqttService,
    NativeService,
    { provide: 'WINDOW', useFactory: windowFactory },
    Table,
    TableService,
    UserService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
